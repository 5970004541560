import { colors, WMKGradient } from "../../../vars/palette";

// place styles directly inside the styling for an anchor tag -  a { ${ctaStyles} }  - might work for other types of tags as well, but, so far, it's only been used and tested with anchor tags
export const buttonStyles = `
  padding: .25rem 1rem;
  border: 2px solid white;
  background: ${WMKGradient.grad()};
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  transform: scale(1);
  transition: all .3s ease;
  :hover {
    transform: scale(1.1);
    transition: all .3s ease;
  }
  :active, :focus {
    border: 2px solid #FF9200;
    background: none;
    transition: all .3s ease;
  }
`;

export const altButtonStyles = `
  background: none;
  border: 2px solid ${colors.hex("secondary")};
  border-radius: 0;
  color: ${colors.hex("secondary")};
  text-transform: uppercase;
  font-weight: bold;
  transform: scale(1);
  transition: all .3s ease;
  :hover, :active, :focus {
    transform: scale(1.1);
    border: 2px solid white;
    background: ${WMKGradient.grad()};
    color: white;
    transition: all .3s ease;
  }
`;
