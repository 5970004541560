import React, { useState, useEffect } from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import { colors } from "../vars/palette";
import { WmkSeo } from "wmk-seo";
import SolutionsForm from "../components/routes/Solutions/Form";
import SolutionHero from "../components/routes/Solutions/SolutionHero";
import SolutionNav from "../components/routes/Solutions/SolutionNav";
import { Solution } from "../classes/Solution";
import { SolutionFields } from "../fragments/nodeSolutionFields";
import { RichTextReact } from "wmk-rich-text";
import { options } from "../components/blocks/RichText/richTextOptions";
import { BlockIconButtonsRowFields } from "../fragments/blockIconButtonsRowFields";
import { IconButtonsRow } from "../classes/IconButtonsRow";
import BlockIconButtonsRow from "../components/blocks/library/BlockIconButtonsRow/BlockIconButtonsRow";

const Wrap = styled.div<{ refHeight: number; isstuck: boolean }>`
  p {
    font-weight: 400;
  }
  .intro-col {
    text-align: center;
    padding: 0 20%;
    padding-top: ${({ refHeight, isstuck }) =>
      refHeight && isstuck ? `${refHeight * 1.5}px` : `0`};
    color: ${colors.hex("secondary")};
    p {
      font-weight: 600;
    }
    @media only screen and (max-width: 767px) {
      text-align: left;
      padding-right: calc(var(--bs-gutter-x));
      padding-left: calc(var(--bs-gutter-x));
    }
  }
`;

const BlocksWrap = styled.div`
  position: relative;
  .block {
    margin: 6rem 0;
  }
  h3 {
    color: #696969;
  }
  p {
    color: #696969;
    font-size: 16px;
  }
  ul {
    list-style-type: none;
    li {
      display: flex;
      // margin-top: -8px;
      text-align: left;
      :before {
        content: "//  ";
        margin: 0 7px 0 -10px;
        color: ${colors.hex("secondary")};
      }
    }
  }
  code {
    display: block;
    margin-top: 0.5rem;
  }
  a {
    text-decoration: none;
    display: block;
  }
  // a code {
  //   display: block;
  //   position: relative;
  //   font-size: 16px;
  //   text-align: center;
  //   text-decoration: none !important;
  //   color: #ff9200 !important;
  //   position: relative;
  //   left: -10px;
  //   transform: scale(1);
  //   transition: all 0.3s ease;
  //   &:hover {
  //     transform: scale(1.1);
  //     transition: all 0.3s ease;
  //   }
  // }
  // a code b,
  // a code i,
  // a b code,
  // a i code {
  //   &:after {
  //     content: "  →";
  //     font-size: 30px;
  //     position: relative;
  //     top: 5px;
  //   }
  // }
  // a code b,
  // a b code {
  //   // targeting the cta links under the images (desktop cta)
  //   display: default;
  //   font-weight: 500;
  // }
  // a code i,
  // a i code {
  //   // targeting the cta links under the copy (mobile cta)
  //   display: none;
  //   font-style: normal;
  // }
  @media only screen and (max-width: 991px) {
    * {
      text-align: left;
    }
    // a code {
    //   &:hover {
    //     transform: scale(1) !important;
    //   }
    // }
    // a code b,
    // a b code {
    //   // targeting the cta links under the images (desktop cta)
    //   display: none;
    // }
    // a code i,
    // a i code {
    //   // targeting the cta links under the copy (mobile cta)
    //   display: block;
    // }
  }
`;

export default ({ data }: SolutionQuery) => {
  const [viewWidth, setViewWidth] = useState(0);
  const [refHeight, setRefHeight] = useState(0);
  const [isStuck, setIsStuck] = useState(false);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const { solution, solutionsRow } = data;
  const _solution = new Solution(solution);
  const title = _solution.title;
  const slug = _solution.slug;
  const content = _solution.content;
  const metaDescription = _solution.meta;
  const solutionsRowClassed = new IconButtonsRow(solutionsRow);
  const solutionHeroImage = _solution.heroImage;
  return (
    <Layout>
      <WmkSeo.Meta
        title={title}
        path="/solutions/"
        slug={slug}
        description={metaDescription}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      <WmkSeo.Schema
        graphs={[
          {
            "@type": "WebPage",
            "@id": baseUrl + "/#page-" + slug,
            url: baseUrl,
            inLanguage: "en-US",
            name: title,
            isPartOf: {
              "@id": baseUrl + "/#website",
            },
            about: {
              "@id": baseUrl + "/#organization",
            },
            description: metaDescription,
          },
        ]}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      <Wrap refHeight={refHeight} isstuck={isStuck}>
        <SolutionHero
          currPage={title}
          image={solutionHeroImage}
          video={_solution.heroVideo}
          width={viewWidth}
          link={_solution.headerLink}
          copy={solution.introduction?.introduction}
        />
        <SolutionNav
          currPage={title}
          refHeight={refHeight}
          setRefHeight={setRefHeight}
          isStuck={isStuck}
          setIsStuck={setIsStuck}
          viewWidth={viewWidth}
          introduction={solution.introduction?.introduction ? solution.introduction?.introduction : ""}
          link={_solution.headerLink}
        />
        <Container className="body" fluid>
          <BlocksWrap>
            <RichTextReact content={content} options={options} />
          </BlocksWrap>
        </Container>
        <BlockIconButtonsRow block={solutionsRowClassed} currSol={title} />
        <SolutionsForm formDescription={_solution.formDescription} formHeader={_solution.formHeader} />
      </Wrap>
    </Layout>
  );
};

export interface SolutionQuery {
  data: {
    solution: SolutionFields;
    solutionsRow: BlockIconButtonsRowFields;
    meta: {
      title: string;
      baseUrl: string;
    };
  };
}

export const query = graphql`
  query ($slug: String!) {
    solution: contentfulWmkSolutions(slug: { eq: $slug }) {
      ...NodeSolutionFields
    }
    solutionsRow: contentfulBlockIconButtonsRow(
      title: { eq: "Solutions Page Icon Buttons" }
    ) {
      ...BlockIconButtonsRowFields
    }
  }
`;
